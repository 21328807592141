/* eslint-disable no-param-reassign */
import { GridColumnsInitialState, GridFilterModel, GridPinnedColumnFields } from '@mui/x-data-grid-premium';
import { PayloadAction, createAction, createSlice } from '@reduxjs/toolkit';
import { MUIDataGridState } from '../types/MUIDataGridState';
import { DateRange } from '../types/DateRange';
import { getLastDaysDateRange } from '../utils/dateUtils';
import { MUIDataGridKey } from '../constants/MUIDataGrid';

const initialState: MUIDataGridState = {
  currentKey: MUIDataGridKey.Default,
  [MUIDataGridKey.Default]: {
    dateRangeOptionId: 0,
    dateRangeFilter: getLastDaysDateRange(45),
    filterModel: undefined,
    searchTerm: undefined,
  },
};

export const updateDataGridState = createAction<{
  dataGridKey: MUIDataGridKey;
  reset?: boolean;
  pinnedColumns?: GridPinnedColumnFields | undefined;
  columns?: GridColumnsInitialState | undefined;
}>('muiDataGrid/updateDataGridState');

export const muiDataGridSlice = createSlice({
  name: 'muiDataGrid',
  initialState,
  reducers: {
    setDateRangeFilter(
      state,
      action: PayloadAction<{
        dateRangeFilter: DateRange;
      }>,
    ) {
      const keyState = state[state.currentKey] || state[MUIDataGridKey.Default];
      keyState.dateRangeFilter = action.payload.dateRangeFilter;
    },
    setDataGridKey(state, action: PayloadAction<MUIDataGridKey>) {
      state.currentKey = action.payload;
      if (!state[action.payload]) {
        state[action.payload] = {
          dateRangeOptionId: 0,
          dateRangeFilter: getLastDaysDateRange(45),
          filterModel: undefined,
          searchTerm: undefined,
        };
      }
    },
    setDataGridResetting(state, action: PayloadAction<boolean>) {
      const keyState = state[state.currentKey] || state[MUIDataGridKey.Default];
      keyState.resetting = action.payload;
    },
    setDateRangeOptionId(state, action: PayloadAction<number>) {
      const keyState = state[state.currentKey] || state[MUIDataGridKey.Default];
      keyState.dateRangeOptionId = action.payload;
    },
    setFilterModel(state, action: PayloadAction<GridFilterModel>) {
      const keyState = state[state.currentKey] || state[MUIDataGridKey.Default];
      keyState.filterModel = action.payload;
    },
    setSearchTerm(state, action: PayloadAction<string | undefined>) {
      const keyState = state[state.currentKey] || state[MUIDataGridKey.Default];
      keyState.searchTerm = action.payload;
    },
  },
});

export const {
  setDateRangeFilter,
  setDateRangeOptionId,
  setFilterModel,
  setSearchTerm,
  setDataGridKey,
  setDataGridResetting,
} = muiDataGridSlice.actions;
