import { createApi } from '@reduxjs/toolkit/query/react';
import { API_NAMES } from '../constants/App';
import { amplifyAPIBaseQuery, getRequestHeader } from '../utils/apiUtils';
import { Dealer } from '../types/Dealer';
import { DealerCacheTagType } from '../constants/Dealer';
import { encodeParam } from '../utils/urlUtils';

export const dealerApi = createApi({
  reducerPath: 'dealerApi',
  refetchOnFocus: true,
  refetchOnReconnect: true,
  tagTypes: [DealerCacheTagType.DealersByClientId, DealerCacheTagType.DealersByGroup, DealerCacheTagType.Dealer],
  baseQuery: amplifyAPIBaseQuery({
    apiName: API_NAMES.API_PUBLIC,
    baseUrl: '/v1/internal/dealers',
  }),
  endpoints: (builder) => ({
    getDealersByClientIds: builder.query<
      { dealers: Dealer[] },
      {
        clientIds: string[];
        groupId: string;
      }
    >({
      query: ({ clientIds, groupId }) => ({
        url: `/all`,
        method: 'post',
        init: {
          body: {
            clientIds,
            groupId,
          },
        },
      }),
      providesTags: (result, error, { clientIds }) =>
        result ? clientIds.map((clientId) => ({ type: DealerCacheTagType.DealersByClientId, id: clientId })) : [],
    }),

    /**
     * Gets a list of dealers for a group
     */
    getDealersByGroup: builder.query<
      Dealer[],
      {
        clientId: string;
        groupId: string;
      }
    >({
      query: ({ clientId, groupId }) => ({
        url: `/group/${encodeParam(groupId)}`,
        method: 'get',
        init: {
          headers: getRequestHeader({ clientId }),
        },
      }),
      providesTags: (result, error, { groupId }) =>
        result ? [{ type: DealerCacheTagType.DealersByGroup, id: groupId }] : [],
    }),

    /**
     * Adds or updates a dealer
     */
    updateDealer: builder.mutation<
      void,
      {
        groupId: string;
        clientId: string;
        id: number | undefined;
        dealer: Dealer;
      }
    >({
      query: ({ groupId, clientId, id, dealer }) => ({
        url: `/update/${id}`,
        method: 'put',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
          body: {
            dealer,
          },
        },
      }),
      invalidatesTags: (result, error, { groupId, clientId }) => [
        { type: DealerCacheTagType.DealersByGroup, id: groupId },
        { type: DealerCacheTagType.DealersByClientId, id: clientId },
      ],
    }),

    addDealers: builder.mutation<
      void,
      {
        groupId: string;
        clientId: string;
        dealers: Dealer[];
      }
    >({
      query: ({ groupId, clientId, dealers }) => ({
        url: `/add`,
        method: 'post',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
          body: {
            dealers,
          },
        },
      }),
      invalidatesTags: (result, error, { groupId, clientId }) => [
        { type: DealerCacheTagType.DealersByGroup, id: groupId },
        { type: DealerCacheTagType.DealersByClientId, id: clientId },
      ],
    }),

    /**
     * Deletes a dealer
     */
    deleteDealer: builder.mutation<
      void,
      {
        groupId: string;
        clientId: string;
        id: number | undefined;
      }
    >({
      query: ({ groupId, clientId, id }) => ({
        url: `/delete/${id}`,
        method: 'del',
        init: {
          headers: getRequestHeader({ clientId, groupId }),
        },
      }),
      invalidatesTags: (result, error, { clientId }) => [{ type: DealerCacheTagType.DealersByClientId, id: clientId }],
    }),
  }),
});

export const {
  useGetDealersByClientIdsQuery,
  useGetDealersByGroupQuery,
  useUpdateDealerMutation,
  useDeleteDealerMutation,
} = dealerApi;
