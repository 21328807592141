import React, { useLayoutEffect, useCallback, useMemo } from 'react';
import { GridRenderCellParams, GridColDef, useGridApiRef } from '@mui/x-data-grid-premium';
import { useTranslation } from 'react-i18next';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CheckoutProvider, IntegrationStatus, PaymentStatus } from '@idearoom/types';
import { usePaymentRepo } from '../hooks/usePaymentRepo';
import { useAppDispatch, useAppSelector } from '../hooks';
import { setSecondaryActions } from '../ducks/secondaryActions';
import { formatPhoneNumber } from '../utils/phoneNumberUtils';
import { formatPrice } from '../utils/pricingUtils';
import { compoundCaseToTitleCase, hyphenateString } from '../utils/stringUtils';
import { StatusChip } from './StatusChip';

import { SetupPaymentIntegration } from './SetupPaymentIntegration';
import { setSearchHidden } from '../ducks/search';
import { isCurrentUserAdmin, isIdeaRoomUser } from '../utils/userUtils';
import { MuiDataGrid } from './MUIDataGrid';
import { dateFormatter, getApplyQuickFilterFnVersionedEmailId, getValueOrEmpty } from '../utils/MUIDataGridUtils';
import { I18nKeys } from '../constants/I18nKeys';
import { Loading } from './Loading';
import { AdyenPaymentsNotificationDialog } from './AdyenPaymentNotificationDialog';
import { usePaymentSignupNotification } from '../hooks/usePaymentSignupNotification';
import { canUsePayments } from '../utils/permissionUtils';
import { unknownGroup } from '../constants/Group';

const getColumnExtraProps = (t: Function, paymentMethods?: string[]): GridColDef[] => [
  {
    field: 'date',
    headerName: t(I18nKeys.PaymentGridHeaderDate),
    valueFormatter: dateFormatter,
    type: 'dateTime',
    minWidth: 144,
    filterable: false,
  },
  {
    field: 'status',
    display: 'flex',
    headerName: t(I18nKeys.PaymentGridHeaderPaymentStatus),
    type: 'singleSelect',
    valueOptions: () =>
      Object.values(PaymentStatus).map((ps) => ({
        value: ps,
        label: t(I18nKeys.PaymentGridRowPaymentStatus, { context: ps.toLowerCase() }),
      })),
    valueGetter: (value, row) => row.transaction.status,
    renderCell: (params: GridRenderCellParams<any, PaymentStatus>) => <StatusChip paymentStatus={params.value} />,
    minWidth: 120,
  },
  {
    field: 'customerName',
    headerName: t(I18nKeys.PaymentGridHeaderName),
    type: 'string',
    minWidth: 144,
  },
  {
    field: 'customerEmail',
    headerName: t(I18nKeys.PaymentGridHeaderEmail),
    type: 'string',
    minWidth: 176,
  },
  {
    field: 'customerPhone',
    headerName: t(I18nKeys.PaymentGridHeaderPhone),
    valueFormatter: formatPhoneNumber,
    type: 'string',
    minWidth: 120,
  },
  {
    field: 'billingAddress',
    headerName: t(I18nKeys.PaymentGridHeaderBillingAddress),
    type: 'string',
    valueGetter: (value, row) => getValueOrEmpty(row.billingAddress?.address1),
    minWidth: 184,
  },
  {
    field: 'billingCity',
    headerName: t(I18nKeys.PaymentGridHeaderBillingCity),
    type: 'string',
    valueGetter: (value, row) => getValueOrEmpty(row.billingAddress?.city),
    minWidth: 88,
  },
  {
    field: 'billingState',
    headerName: t(I18nKeys.PaymentGridHeaderBillingState),
    type: 'string',
    valueGetter: (value, row) => getValueOrEmpty(row.billingAddress?.state),
    minWidth: 88,
  },
  {
    field: 'billingPostalCode',
    headerName: t(I18nKeys.PaymentGridHeaderBillingPostalCode),
    type: 'string',
    valueGetter: (value, row) => getValueOrEmpty(row.billingAddress?.zip),
    minWidth: 88,
  },
  {
    field: 'paymentAmount',
    headerName: t(I18nKeys.PaymentGridHeaderPaymentAmount),
    valueFormatter: (value, row) => formatPrice(value, row?.transaction?.currency || 'USD', 2),
    type: 'number',
    valueGetter: (value, row) => row?.transaction?.amount,
    minWidth: 88,
  },
  {
    field: 'payoutAmount',
    headerName: t(I18nKeys.PaymentGridHeaderPayoutAmount),
    valueFormatter: (value, row) => formatPrice(value, row?.transaction?.currency || 'USD', 2),
    type: 'number',
    valueGetter: (value, row) => row?.transaction?.payoutAmount,
    minWidth: 88,
  },
  {
    field: 'processingFee',
    headerName: t(I18nKeys.PaymentGridHeaderProcessingFee),
    valueFormatter: (value, row) => formatPrice(value, row?.transaction?.currency || 'USD', 2),
    type: 'number',
    valueGetter: (value, row) => row?.transaction?.processingFee,
    minWidth: 88,
  },
  {
    field: 'payoutDate',
    headerName: t(I18nKeys.PaymentGridHeaderPayoutDate),
    valueFormatter: (value) => dateFormatter(value, false) || '-',
    type: 'dateTime',
    valueGetter: (value, row) => row?.transaction?.payoutDate,
    minWidth: 144,
  },
  {
    field: 'paymentMethod',
    headerName: t(I18nKeys.PaymentGridHeaderPaymentMethod),
    type: 'singleSelect',
    valueOptions: paymentMethods?.map((pm) => ({
      value: pm,
      label:
        t(I18nKeys.PaymentGridRowPaymentMethod, { defaultValue: compoundCaseToTitleCase(pm), context: pm }) ||
        compoundCaseToTitleCase(pm),
    })),
    valueFormatter: (value) =>
      t(I18nKeys.PaymentGridRowPaymentMethod, { defaultValue: compoundCaseToTitleCase(value), context: value }) ||
      compoundCaseToTitleCase(value),
    valueGetter: (value, row) => row.transaction.paymentMethod,
    minWidth: 80,
  },
  {
    field: 'provider',
    headerName: t(I18nKeys.PaymentGridHeaderPaymentProvider),
    type: 'singleSelect',
    valueOptions: () =>
      Object.values(CheckoutProvider).map((cp) => ({
        value: cp,
        label: compoundCaseToTitleCase(cp),
      })),
    valueFormatter: (value) => compoundCaseToTitleCase(value),
    valueGetter: (value, row) => row.transaction.provider,
    minWidth: 88,
  },
  {
    field: 'transactionId',
    headerName: t(I18nKeys.PaymentGridHeaderTransactionId),
    type: 'string',
    valueGetter: (value, row) => row.transaction.id,
  },
  {
    field: 'versionedEmailId',
    headerName: t(I18nKeys.PaymentGridHeaderOrderNumber),
    type: 'string',
    minWidth: 160,
    valueFormatter: (value) => hyphenateString(value),
    getApplyQuickFilterFn: getApplyQuickFilterFnVersionedEmailId,
  },
  {
    field: 'reason',
    headerName: t(I18nKeys.PaymentGridHeaderPaymentReason),
    valueGetter: (value, row) => row?.transaction?.reason,
    minWidth: 160,
  },
];

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    background: '#F5F5F5',
    '& .MuiDataGrid-footerContainer': {
      background: '#F5F5F5',
    },
  },
  chip: {
    padding: '4px 12px 4px 12px',
    display: 'flex',
    alignItems: 'center',
    margin: '0px 12px',
    gap: '8px',
    borderRadius: '16px',
    opacity: '0px',
    background: 'red',
  },
  chipText: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
    letterSpacing: '0.25px',
    textAlign: 'left',
  },
}));

export const Payments: React.FC = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const apiRef = useGridApiRef();
  usePaymentSignupNotification();

  const { user, group: { groupId } = unknownGroup } = useAppSelector((state) => state.currentUser);
  const { paymentIntegrationStatus } = useAppSelector((state) => state.viewer);
  const { payments, isLoadingPayments } = usePaymentRepo({ usePayments: true });

  const columns = useMemo(
    () =>
      getColumnExtraProps(
        t,
        payments.reduce<string[]>((acc, value) => {
          const { paymentMethod = '' } = value.transaction || {};
          if (!acc.includes(paymentMethod)) {
            acc.push(paymentMethod);
          }
          return acc;
        }, []),
      ),
    [t, payments],
  );

  const handleExport = useCallback(() => {
    apiRef?.current?.exportDataAsCsv();
  }, [apiRef]);

  useLayoutEffect(() => {
    const connected =
      paymentIntegrationStatus?.status === IntegrationStatus.Connected ||
      paymentIntegrationStatus?.status === IntegrationStatus.Deactivated;
    dispatch(setSearchHidden(!connected));
    if (connected && (isIdeaRoomUser(user) || isCurrentUserAdmin(user))) {
      dispatch(
        setSecondaryActions([
          <IconButton color="secondary" size="small" onClick={handleExport}>
            <FileDownloadOutlinedIcon />
          </IconButton>,
        ]),
      );
    }
  }, [dispatch, paymentIntegrationStatus, user, handleExport]);

  if (!paymentIntegrationStatus) {
    return <Loading />;
  }

  if (!user || !canUsePayments(user, groupId, paymentIntegrationStatus.status)) {
    return <div />;
  }

  if (
    paymentIntegrationStatus.status !== IntegrationStatus.Connected &&
    paymentIntegrationStatus.status !== IntegrationStatus.Deactivated
  ) {
    return <SetupPaymentIntegration />;
  }

  return (
    <div className={classes.root}>
      <MuiDataGrid
        loading={isLoadingPayments}
        rows={payments}
        columns={columns}
        handleSearch={
          paymentIntegrationStatus?.status === IntegrationStatus.Connected ||
          paymentIntegrationStatus?.status === IntegrationStatus.Deactivated
        }
        apiRef={apiRef}
        getRowId={(row) => row.hash}
        autoHeight
        enableDateFilter
        initialState={{
          columns: {
            columnVisibilityModel: {
              reason: false,
            },
            orderedFields: columns.map((col) => col.field),
          },
        }}
        countParams={{
          countLabel: I18nKeys.PaymentGridPaymentCount,
          filteredLabel: I18nKeys.PaymentGridFilteredPaymentCount,
        }}
      />
      <AdyenPaymentsNotificationDialog />
    </div>
  );
};
