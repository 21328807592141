/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState } from 'react';
import { GridColumnMenuProps, useGridApiContext, ColumnsPanelPropsOverrides } from '@mui/x-data-grid-premium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { Box, Theme, Typography, Button, FormControlLabel, Checkbox, Divider, Stack } from '@mui/material';
import { Search } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../hooks';
import { I18nKeys } from '../constants/I18nKeys';
import { UserPreference } from '../constants/User';
import { SearchInput } from './SearchInput';
import { setDataGridResetting, updateDataGridState } from '../ducks/muiDataGridSlice';

declare module '@mui/x-data-grid-premium' {
  interface ColumnsPanelPropsOverrides {
    initialState?: GridInitialStatePremium | undefined;
  }
}

const useStyles = makeStyles<Theme, { cellSelection: boolean }>(() => ({
  searchField: {
    padding: '12px 24px',
  },
  columnsPanelBottom: {
    padding: '4px 24px',
  },
  columnsPanelNoColumnsFound: {
    padding: '4px 24px 12px',
    fontSize: '16px',
    fontWeight: 400,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: 'rgb(158, 158, 158)',
  },
  columnsPanel: {
    [`& .MuiInputBase-root`]: {
      minHeight: '48px',
    },
    [`& .MuiOutlinedInput-input`]: {
      fontSize: '16px',
      fontWeight: '400',
      lineHeight: '24px',
      letterSpacing: '0.5px',
    },
    [`& .MuiFormControlLabel-root`]: {
      display: 'flex',
      minHeight: '34px',
      padding: '0px 24px',
    },
    [`& .MuiCheckbox-root`]: {
      padding: '0px',
      marginRight: '25px',
    },
    [`& .MuiTypography-root`]: {
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      letterSpacing: '0.25px',
    },
    [`& .MuiButton-text`]: {
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '16px',
      letterSpacing: '0.75px',
    },
  },
}));

export const MUIDataGridCustomPanel: React.FC<GridColumnMenuProps & ColumnsPanelPropsOverrides> = ({
  initialState,
}) => {
  const classes = useStyles({ cellSelection: false });
  const apiRef = useGridApiContext();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [columnsSearchTerm, setColumnsSearchTerm] = useState('');
  const columns = apiRef.current.getAllColumns();
  const visibleColumns = apiRef.current.getVisibleColumns();
  const allVisible = visibleColumns.length === columns.length;
  const dataGridKey = useAppSelector((state) => state.muiDataGrid.currentKey);
  const { preferences: existingPreferences } = useAppSelector((state) => state.currentUser);
  const muiDataGridPreferences = existingPreferences?.[UserPreference.MUIDataGrid] || {};
  const { columns: columnsState = {}, pinnedColumns } = muiDataGridPreferences[dataGridKey] || {};
  const resetDisabled = useMemo(() => {
    let pinnedColumnsEquals = false;
    let columnVisibilityModelEquals = false;
    let orderedFieldsEquals = false;
    if (initialState) {
      const { left: initialLeft = [], right: initialRight = [] } = initialState.pinnedColumns || {};
      const { left = [], right = [] } = pinnedColumns || {};
      if (
        initialLeft.length === left.length &&
        initialRight.length === right.length &&
        initialLeft.every((l) => left.includes(l)) &&
        initialRight.every((r) => right.includes(r))
      ) {
        pinnedColumnsEquals = true;
      }

      const { columnVisibilityModel: initialColumnVisibilityModel = {}, orderedFields: initialOrderedFields = [] } =
        initialState.columns || {};
      const { columnVisibilityModel = {}, orderedFields = [] } = columnsState || {};
      if (
        Object.keys(columnVisibilityModel).every(
          (key) =>
            (columnVisibilityModel[key] && initialColumnVisibilityModel[key] == null) ||
            initialColumnVisibilityModel[key] === columnVisibilityModel[key],
        )
      ) {
        columnVisibilityModelEquals = true;
      }

      if (
        initialOrderedFields.length === orderedFields.length &&
        initialOrderedFields.every((field, index) => orderedFields[index] === field)
      ) {
        orderedFieldsEquals = true;
      }
    }
    return pinnedColumnsEquals && columnVisibilityModelEquals && orderedFieldsEquals;
  }, [initialState, pinnedColumns, columnsState]);

  const handleReset = () => {
    dispatch(setDataGridResetting(true));
    const currentVisibility = initialState?.columns?.columnVisibilityModel;
    const columnVisibilityModel = columns.reduce(
      (acc, column) => ({
        ...acc,
        [column.field]: currentVisibility?.[column.field] != null ? currentVisibility?.[column.field] : true,
      }),
      {},
    );

    const newState = {
      pinnedColumns: {
        left: initialState?.pinnedColumns?.left || [],
        right: initialState?.pinnedColumns?.right || [],
      },
      columns: {
        ...columnsState,
        ...(initialState?.columns || {}),
        columnVisibilityModel,
      },
    };

    apiRef.current.restoreState({
      ...newState,
    });

    dispatch(
      updateDataGridState({
        dataGridKey,
        reset: true,
        ...newState,
      }),
    );
    dispatch(setDataGridResetting(false));
  };

  const handleSearchChange = (searchValue: string) => {
    setColumnsSearchTerm(searchValue);
  };

  const endSearch = (): void => {
    handleSearchChange('');
  };

  const filteredColumns = columns.filter((column) =>
    column.headerName?.toLowerCase().includes(columnsSearchTerm.toLowerCase()),
  );

  const handleColumnToggle = (field: string, visible: boolean) => {
    if (visible || visibleColumns.length > 1) {
      apiRef.current.setColumnVisibility(field, visible);
    }
  };

  const toggleAll = () => {
    apiRef.current.setColumnVisibilityModel(
      columns.reduce((acc, column, index) => ({ ...acc, [column.field]: index === 0 || !allVisible }), {}),
    );
  };

  return (
    <Box
      sx={{
        minWidth: '300px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className={classes.columnsPanel}>
        <SearchInput
          classes={classes}
          searchTerm={columnsSearchTerm}
          startAdornment={<Search />}
          onClearClick={endSearch}
          autoFocus
          onChange={(val: string) => {
            handleSearchChange(val);
          }}
        />

        <Box sx={{ maxHeight: 330, overflowY: 'auto' }}>
          {filteredColumns.length > 0 ? (
            filteredColumns.map((column) => {
              const isVisible = visibleColumns.some((col) => col.field === column.field);
              return (
                <FormControlLabel
                  key={column.field}
                  control={
                    <Checkbox
                      checked={isVisible}
                      onChange={(e) => handleColumnToggle(column.field, e.target.checked)}
                    />
                  }
                  label={column.headerName || column.field}
                  sx={{ display: 'block', marginLeft: 0 }}
                />
              );
            })
          ) : (
            <Typography variant="body2" color="text.secondary" className={classes.columnsPanelNoColumnsFound}>
              {t(I18nKeys.MUIDataGridNoColumnFound)}
            </Typography>
          )}
        </Box>

        <Divider />
        <Stack direction="row" spacing={2} paddingBlock={0.5} paddingInlineEnd={1} justifyContent="space-between">
          <FormControlLabel
            key="toggleAll"
            control={<Checkbox checked={allVisible} indeterminate={!allVisible} onChange={toggleAll} />}
            label={t(I18nKeys.MUIDataGridShowHideAll)}
            sx={{ display: 'block', marginLeft: 0 }}
          />
          <Button variant="text" onClick={handleReset} disabled={resetDisabled}>
            {t(I18nKeys.MUIDataGridReset)}
          </Button>
        </Stack>
      </div>
    </Box>
  );
};
